import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step51(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title main-title--no-subtitle">Is there a bathroom in the basement?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md col-12">
                    <label className="FancyInput FancyInput--first">
                        <input type="radio"
                               className="FancyInput__radio-button"
                               name="basementHasBathroom"
                               value="yes"
                               defaultChecked={props.basementHasBathroom === 'Yes'}
                               checked={props.basementHasBathroom === 'Yes'}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('basementHasBathroom', 'Yes')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Yes</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md col-12">
                    <label className="FancyInput FancyInput--last">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="basementHasBathroom"
                            value="no"
                            defaultChecked={props.basementHasBathroom === 'No'}
                            checked={props.basementHasBathroom === 'No'}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('basementHasBathroom', 'No')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">No</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <PoseGroup>
                {props.basementHasBathroom === "Yes"  && (
                    <AnimatedBox key="basementHasBathroom">
                        <div className="row">
                            <div className="col col-md">
                                <div className="FancyInputArea__section-heading">
                                    <p>Bathroom Configuration</p>
                                </div>
                            </div>
                        </div>

                        <label className="FancyInput FancyInput--first">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="bathroomConfig"
                                defaultChecked={props.bathroomConfig === 'Unfinished'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('bathroomConfig', 'Unfinished')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">Unfinished Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Water supply and drain pipes have been roughed in</p>
                                    </div>
                                    <div>
                                    <span className="custom-radio">
                                        <span className="custom-radio__inner"></span>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </label>

                        <label className="FancyInput FancyInput--last">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="bathroomConfig"
                                defaultChecked={props.bathroomConfig === 'Existing'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('bathroomConfig', 'Existing')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">Existing Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Bathroom is already finished and needs to be remodelled</p>
                                    </div>
                                    <div>
                                    <span className="custom-radio">
                                        <span className="custom-radio__inner"></span>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </AnimatedBox>
                )}
            </PoseGroup>

        </div>
    )

}