import React from 'react';

export default class Step3 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <section className="form">
          <form className="step-3 get-estimate-form" autoComplete="off">
            <div className="container-almost-full">
              <div className="flex-row d-flex align-items-center justify-content-around">
                <div className="col-md-10 col-lg-10 text-center">
                  <h2 className="sub-title">
                    Thanks again, {this.props.fieldValues.name}
                  </h2>
                </div>
              </div>

              <div className="row row-flex align-items-center justify-content-around">
                <div className="col-md-8 step-3-text">
                  <p>
                    I will contact you to discuss the preliminary details of
                    your renovation project and schedule a time to see your
                    space
                    {this.props.fieldValues.virtualMeet === 'yes'
                      ? ' virtually'
                      : ''}
                    . I want to remind you that there are no obligations tied to
                    this consultation - this is an opportunity for us to meet
                    and for you to ask questions. There are no up-front costs
                    either and the personalized fixed-price proposal is entirely
                    free.
                  </p>

                  <p>
                    In the meantime, feel free to get in touch with me if you
                    have additional questions. I can also be reached via{' '}
                    <a href="mailto:kal@elfarro.com">email</a> or on my personal
                    cell phone at <a href="tel:6138946554">(613) 894-6554</a>.
                  </p>

                  <p>I look forward to working with you.</p>

                  <p>— Kal Elfarro</p>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    );
  }
}
