import React from 'react';
import $ from 'jquery';
import validate from 'jquery-validation';
import Geosuggest from 'react-geosuggest';
import autosize from 'autosize';
import axios from 'axios';
import autoBind from 'react-autobind';
import LaddaButton, { ZOOM_IN } from 'react-ladda';

export default class Step2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedValue: null,
      selectedValueCheckbox: false,
    };

    autoBind(this);
  }

  componentDidMount() {
    $('.input-item input, .input-item textarea').on('focus blur', function () {
      $(this).parent().parent().toggleClass('input-item--active');
    });

    autosize($('.autoresize'));

    $('.step-2.get-estimate-form').validate({
      errorPlacement: (error, element) => {
        error.insertAfter($(element).parent().parent());
      },

      rules: {
        renovation: {
          maxlength: 20,
        },

        budget: {
          maxlength: 200,
        },

        timeline: {
          maxlength: 200,
        },

        virtualMeet: {
          maxLength: 3,
        },

        address: {
          maxlength: 300,
        },
      },
    });
  }

  _limitCharacters(e) {
    return e.currentTarget.value.length <= 200;
  }

  continueWithoutData() {
    this.props.nextStep();
  }

  toggle() {
    this.setState({
      loading: !this.state.loading,
    });
  }

  saveAndContinue(e) {
    e.preventDefault();

    if ($('.step-2.get-estimate-form').valid()) {
      this.toggle();
      //saveData in the database
      // update the field values as well in the parent component

      this.props.saveValues({
        renovationType: this.state.selectedValue,
        budget: this.refs.budget.value,
        timeline: this.refs.timeline.value,
        virtualMeet: this.state.selectedValueCheckbox ? 'yes' : 'no',
        address: document.querySelector('.address').value,
        userEmail: this.props.fieldValues.email,
        step2Done: true,
      });

      axios
        .post('/signup-additional', {
          renovation_type: this.state.selectedValue,
          budget: this.refs.budget.value,
          virtualMeet: this.state.selectedValueCheckbox ? 'yes' : 'no',
          timeline: this.refs.timeline.value,
          address: document.querySelector('.address').value,
          userEmail: this.props.fieldValues.email,
          leadId: this.props.fieldValues.userId,
        })
        .then((response) => {
          console.log(response);

          this.props.nextStep();

          $('html, body').animate(
            {
              scrollTop: 0,
            },
            50
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  _checkboxChanged(e) {
    console.log('test');
    console.log(e.target.checked);
    this.setState({ selectedValueCheckbox: e.target.checked });
  }

  _selectChanged(e) {
    this.setState({ selectedValue: e.currentTarget.value });
  }

  render() {
    return (
      <div>
        <section className="form">
          <form className="step-2 get-estimate-form" autoComplete="off">
            <div className="container-almost-full">
              <div className="row row-flex align-items-center justify-content-around step-1-success-intro">
                <div className="col-md-10 col-lg-10 text-center">
                  <h2 className="sub-title">
                    Thanks, {this.props.fieldValues.name}. Tell us more about
                    your project.
                  </h2>
                </div>
              </div>

              <div className="row row-flex align-items-center justify-content-around">
                <div className="col-md-8">
                  <div className="input-item">
                    <div className="input-wrapper">
                      <select
                        id="renovation-type"
                        ref="renovation_type"
                        name="renovation"
                        onChange={this._selectChanged}
                      >
                        <option selected disabled>
                          Type of renovation
                        </option>
                        <option value="Addition">Addition</option>
                        <option value="Basement">Basement</option>
                        <option value="Bathroom">Bathroom</option>
                        <option value="Custom Build">Custom Build</option>
                        <option value="Kitchen">Kitchen</option>
                        <option value="Renovation">Renovation</option>
                        <option value="Other">Other</option>
                      </select>
                      <span className="select-arrow"></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="input-item">
                    <div className="input-wrapper">
                      <textarea
                        name="budget"
                        ref="budget"
                        maxLength="200"
                        placeholder="What is your budget?"
                        rows="1"
                        className="autoresize"
                        onKeyDown={this._limitCharacters}
                      ></textarea>
                      <span className="success-check"></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="input-item">
                    <div className="input-wrapper">
                      <textarea
                        name="timeline"
                        ref="timeline"
                        maxLength="200"
                        placeholder="What is your timeline?"
                        rows="1"
                        className="autoresize"
                        onKeyDown={this._limitCharacters}
                      ></textarea>
                      <span className="success-check"></span>
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="input-item input-item--autocomplete">
                    <div className="input-wrapper">
                      <Geosuggest
                        inputClassName="address"
                        ref="address"
                        placeholder="Address"
                        country="ca"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-8">
                  <div
                    className="input-item"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 0,
                    }}
                  >
                    <input
                      type="checkbox"
                      id="virtualMeet"
                      htmlId="virtualMeet"
                      ref="virtualMeet"
                      onChange={this._checkboxChanged}
                      style={{ width: '16px', marginRight: '10px' }}
                    />

                    <label
                      for="virtualMeet"
                      style={{ marginBottom: 0, userSelect: 'none' }}
                    >
                      I prefer a virtual video chat instead of an in-home
                      consultation.
                    </label>
                  </div>
                </div>

                <div className="col-md-8">
                  <div className="input-item">
                    <LaddaButton
                      className="btn"
                      loading={this.state.loading}
                      onClick={this.saveAndContinue}
                      data-color="#32dd9d"
                      data-style={ZOOM_IN}
                      data-spinner-size={30}
                      data-spinner-color="#ddd"
                      data-spinner-lines={12}
                    >
                      send <span className="link-arrow">→</span>
                    </LaddaButton>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    );
  }
}
