import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step54(props) {

    let plumbingFixturesRemainingToAdd = [];

    if (props.plumbingFixtureExisting === '3piece') {
        plumbingFixturesRemainingToAdd = ['Walk-in Shower', 'Bathtub', 'Double Sinks'].filter(e => e !== props.additionalPlumbingFixtureExistingBasement);
        console.log(['Walk-in Shower', 'Bathtub', 'Double Sinks'].filter(e => e !== props.additionalPlumbingFixtureExistingBasement));
    }

    console.log(plumbingFixturesRemainingToAdd);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h1 className="main-title main-title--no-subtitle">Do you want to add additional plumbing fixtures?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12">
                    <label className="FancyInput">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="additionalPlumbingFixturesBasement"
                            value="yes"
                            checked={props.addAdditionalPlumbingFixturesBasement === 'Yes'}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('addAdditionalPlumbingFixturesBasement', 'Yes')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Yes</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md-6 col-12">
                    <label className="FancyInput FancyInput--last">
                        <input type="radio" className="FancyInput__radio-button" name="addAdditionalPlumbingFixturesBasement" value="no" checked={props.addAdditionalPlumbingFixturesBasement === 'No'} />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('addAdditionalPlumbingFixturesBasement', 'No')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">No</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <PoseGroup>
                {props.addAdditionalPlumbingFixturesBasement === "Yes"  && (
                    <AnimatedBox key='additionalPlumbingFixtures'>
                        <div className="row">
                            <div className="col col-md">
                                <div className="FancyInputArea__section-heading">
                                    <p>Additional Plumbing Fixture</p>
                                </div>
                            </div>
                        </div>

                        {props.plumbingFixtureExistingBasement === '2piece' && (
                            <React.Fragment>
                                {['Walk-in Shower', 'Bathtub'].map((fixture, index) => (
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <label className={`FancyInput ${index === 0 ? 'FancyInput--first' : ''}`}>
                                                <input
                                                    type="radio"
                                                    className="FancyInput__radio-button"
                                                    name="addAdditionalPlumbingFixturesSelectedBasement"
                                                    defaultChecked={props.addAdditionalPlumbingFixturesSelectedBasement === fixture}
                                                />

                                                <div
                                                    className="FancyInput__Border"
                                                    onClick={() => props.modifyData('addAdditionalPlumbingFixturesSelectedBasement', fixture)}
                                                >
                                                    <div className="FancyInput__Text">
                                                        <div>
                                                            <p className="FancyInput__text__title FancyInput__Text__title--dark">{fixture}</p>
                                                        </div>
                                                        <div>
                                                            <span className="custom-radio">
                                                                <span className="custom-radio__inner"></span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        )}
                    </AnimatedBox>
                )}
            </PoseGroup>
        </div>
    )

}