import React from 'react';

export default function Step1(props) {

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h1 className="main-title">Request an Instant Quote<sup>TM</sup></h1>
                    <p className="main-subtitle">Tell us about your project and we can help you instantly
                        calculate our cost for your renovation project with a competitive quote.
                    </p>
                </div>
            </div>

            <div className="row">

                <div className="col-md-6 col-sm-6 col-12">
                    <label htmlFor="kitchen" className="FancyInput FancyInput--image"
                           onClick={() => props.modifyData('renovationType', 'Kitchen')}>
                        <input type="radio" id="kitchen" className="FancyInput__radio-button" name="renovationType"
                               value="kitchen" checked={props.renovationType === "Kitchen"}/>

                        <div className="FancyInput__Border">
                            <img
                                className="FancyInput__Image"
                                srcset="/resources/front/img/Elfarro-Kitchen-414.png 480w, /resources/front/img/Elfarro-Kitchen.png 768w"
                                alt="Elfarro Kitchen"
                            />

                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Kitchen</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md-6 col-sm-6 col-12">
                    <label htmlFor="bathroom" className="FancyInput FancyInput--image" onClick={() => props.modifyData('renovationType','Bathroom')}>
                        <input type="radio" id="bathroom" className="FancyInput__radio-button" name="renovationType" value="bathroom" checked={props.renovationType === "Bathroom"} />
                        <div className="FancyInput__Border">
                            <img
                                srcset="/resources/front/img/Elfarro-Bathroom.png"
                                className="FancyInput__Image"
                                alt="Elfarro Bathroom"
                            />

                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Bathroom</p>
                                <span className="custom-radio">
                                        <span className="custom-radio__inner"></span>
                                    </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md-6 col-sm-6 col-12">
                    <label htmlFor="basement" className="FancyInput FancyInput--image" onClick={() => props.modifyData('renovationType','Basement')}>
                        <input type="radio" id="basement" className="FancyInput__radio-button" name="renovationType" value="basement" checked={props.renovationType === "Basement"} />

                        <div className="FancyInput__Border">
                            <img className="FancyInput__Image" src="/resources/front/img/Elfarro-Kitchen.png" alt="Elfarro Kitchen"/>

                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Basement</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md-6 col-sm-6 col-12">
                    <label htmlFor="basement" className="FancyInput FancyInput--image" onClick={() => props.modifyData('renovationType','Home Addition')}>
                        <input type="radio" id="basement" className="FancyInput__radio-button" name="renovationType" value="basement" checked={props.renovationType === "Home Addition"} />

                        <div className="FancyInput__Border">
                            <img className="FancyInput__Image" src="/resources/front/img/Elfarro-Kitchen.png" alt="Elfarro Home Addition"/>

                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Home Addition</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>


                <div className="col-md-6 col-sm-6 col-12">
                    <label htmlFor="basement" className="FancyInput FancyInput--image" onClick={() => props.modifyData('renovationType','Custom Build')}>
                        <input type="radio" id="basement" className="FancyInput__radio-button" name="renovationType" value="basement" checked={props.renovationType === "Custom Build"} />

                        <div className="FancyInput__Border">
                            <img
                                className="FancyInput__Image"
                                srcset="/resources/front/img/Elfarro-Custom-Build-414.png 480w"
                                alt="Elfarro Custom Build"
                            />
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Custom Build</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    )

}