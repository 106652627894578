import autoBind from "react-autobind";
import React from "react";
import axios from "axios";
import posed from 'react-pose';

import Step1 from './Step1';

//Bathroom
import Step2Bathroom from './Bathroom/Step2';
import Step3Bathroom from './Bathroom/Step3';
import Step4Bathroom from './Bathroom/Step4';
import Step41Bathroom from './Bathroom/Step41';
import Step5Bathroom from './Bathroom/Step5';

import Step2Basement from './Basement/Step2';
import Step21Basement from './Basement/Step21';
import Step3Basement from './Basement/Step3';
import Step4Basement from './Basement/Step4';
import Step5Basement from './Basement/Step5';
import Step51Basement from './Basement/Step51';
import Step52Basement from './Basement/Step52';
import Step53Basement from './Basement/Step53';
import Step54Basement from './Basement/Step54';
import Step55Basement from './Basement/Step55';

import StepDetails from './StepDetails';

import ProgressBar from './ProgressBar';

const AnimatedStepBox = posed.div({
    visible: { opacity: 1, y: 0, transition: { duration: 300 } },
    hidden: { opacity: 0, y: 10, transition: {duration: 300 }},
});

class InstantQuote extends React.Component {

    constructor(props){
        super(props);


        this.state = {
            step: 1,
            subStep: 0,
            progressPercentage: 0,
            stepIsVisible: false,
            loadingIndicatorVisible: false,

            /// Step 1
            renovationType: '',

            //Bathroom Step 2
            existingBathroom: '',
            ensuiteBathroom: false,
            unfinishedBasement: 'Yes',

            //Bathroom Step 3 new bathroom
            plumbingFixtureNew: '',
            additionalPlumbingFixture: '',
            additionalPlumbingFixture4Piece: [],
            additionalPlumbingFixture5Piece: [],

            //Bathroom step 3 existing Bathroom
            plumbingFixtureExisting: '',
            additionalPlumbingFixtureExisting: '',
            additionalPlumbingFixture4PieceExisting: [],
            additionalPlumbingFixture5PieceExisting: [],

            //Bathroom Step4
            addAdditionalPlumbingFixtures: '',
            addAdditionalPlumbingFixturesSelected: [],
            // this is for step 4 substep 0
            addAdditionalPlumbingFixturesSelectedNew: [],

            removeExistingPlumbingFixture: '',
            removeExistingPlumbingFixture3piece: '',
            removeExistingPlumbingFixturesSelected: [],

            //Bathroom Step5
            materialFinish: '',


            // Basement Step 2
            basementArea: '',
            basementAreaDontKnow: '',

            // Basement Step 2-1
            basementAreaGuess: '',

            // Basement Step 3
            basementUnfinished: '',

            // Basement Step 4
            basementFireplace: false,
            basementEgress: false,

            // Basement Step 5
            materialFinishBasement: '',

            // Basement Step 5-1
            basementHasBathroom: '',
            bathroomConfig: '',

            // Basement Step 5-2
            basementBathroomRenovationNeeded: '',

            // Step 5-3 New bathroom - unfinished
            plumbingFixtureNewBasement: '',
            additionalPlumbingFixtureNewBasement: '',

            plumbingFixtureExistingBasement: '',
            additionalPlumbingFixtureExistingBasement: '',

            // Step 5-4 Bathroom

            addAdditionalPlumbingFixturesBasement: '',
            //this field below becomes a string field instead of an array
            // only one fixture addition for basement (no Double Sink)
            addAdditionalPlumbingFixturesSelectedBasement: '',

            // Step 5-5 Bathroom
            removeExistingPlumbingFixtureBasement: '',
            removeExistingPlumbingFixture3pieceBasement: false,


            backButtonPressed: false,
        };

        autoBind(this);
    }

    componentDidMount() {
        window.setTimeout(() => {
            this.setState({
                progressPercentage: 10,
            })
        }, 750)

        window.setTimeout(() => {
            this.setState({
                stepIsVisible: true,
            });
        }, 750);
    }

    _saveValues(fields) {
        // Remember, `fieldValues` is set at the top of this file, we are simply appending
        // to and overriding keys in `fieldValues` with the `fields` using Object.assign
        // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign

        this.fieldValues = Object.assign({}, this.fieldValues, fields);

    }

    modifyData(key, value, arrayField = false, arrayFieldMax = null) {
        console.log(key);
        console.log(value);
        if (arrayField) {
            let previousKeyCopy = this.state[key].slice();

            if (previousKeyCopy.indexOf(value) !== -1) {
                previousKeyCopy.splice(previousKeyCopy.indexOf(value), 1);
            } else {
                if (arrayFieldMax !== null) {
                    if (this.state[key].length === arrayFieldMax) {
                        //array field max limit reached do not push the new element
                        return;
                    }
                }

                previousKeyCopy.push(value);
            }

            this.setState({
                [key]: previousKeyCopy,
            });

        } else {
            const state = {
                [key]: value,
            };

            if (key === 'basementArea') {
                state.basementAreaDontKnow = '';
            }

            this.setState(state);
        }
    }

    initializeStepAnimation() {
        this.setState({
            stepIsVisible: false,
            loadingIndicatorVisible: true,
        });
    }

    resetAnimation() {
        this.setState({
            stepIsVisible: true,
            loadingIndicatorVisible: false,
        });
    }

    changeStep(step, progressPercentage, subStep = 0) {
        this.setState((previousState, props) => ({
            step: step,
            subStep: subStep,
            progressPercentage: progressPercentage,
        }));
    }

    changeStepBathroom(stepType){
        if (stepType === 'next') {

            if (this.state.step === 1) {
                this.changeStep(2, 20);
                return;
            }

            if (this.state.step === 2) {
                this.changeStep(3, 30);
                return;
            }

            if (this.state.step === 3) {
                if (this.state.existingBathroom === "Yes") {
                    // new checks for pieces here

                    if (this.state.plumbingFixtureExisting === "5piece") {
                        this.changeStep(4, 50, 1);
                    } else {
                        this.changeStep(4, 40, 0);
                    }

                } else {
                    this.changeStep(5, 70, 0);
                }

                return;
            }

            if (this.state.step === 4) {
                if (this.state.subStep === 0) {
                    if (this.state.plumbingFixtureExisting === '2piece') {
                        this.changeStep(5, 70);
                        return;
                    } else {
                        this.changeStep(4, 50, 1);
                        return;
                    }
                } else if (this.state.subStep === 1){
                    this.changeStep(5, 70);
                    return;
                }
            }

            if (this.state.step === 5) {
                this.changeStep(99, 85);
                return;
            }

        } else {
            if (this.state.step === 2) {
                this.changeStep(1, 10);
            }

            if (this.state.step === 3) {
                this.changeStep(2, 20);
            }

            if (this.state.step === 4) {
                if (this.state.existingBathroom === 'Yes') {
                    // new checks for pieces here

                    if ((this.state.plumbingFixtureExisting === '5piece' ||
                        this.state.plumbingFixtureExisting === '4piece' ||
                        this.state.plumbingFixtureExisting === '3piece')) {

                        if ((this.state.subStep === 1 && this.state.plumbingFixtureExisting === '5piece') ||
                            this.state.subStep === 0) {
                            this.changeStep(3, 30);
                        } else {
                            this.changeStep(4, 40);
                        }

                    } else if (this.state.plumbingFixtureExisting === '2piece' &&
                        this.state.subStep === 0) {
                        this.changeStep(3, 30);
                    } else {
                        this.changeStep(4, 40);
                    }
                } else {
                    this.changeStep(3, 30);
                }
            }

            if (this.state.step === 5) {

                if (this.state.existingBathroom === "Yes") {
                    // new checks for pieces here

                    if (this.state.plumbingFixtureExisting === '5piece' ||
                        this.state.plumbingFixtureExisting === '4piece' ||
                        this.state.plumbingFixtureExisting === '3piece') {
                        this.changeStep(4, 50, 1);
                    } else {
                        this.changeStep(4, 50);
                    }
                } else {
                    this.changeStep(3, 40);
                }
            }

            if (this.state.step === 99) {
                this.changeStep(5, 70);
            }
        }
    }

    changeStepBasement(stepType) {
        if (stepType === 'next') {

            if (this.state.step === 1) {
                this.changeStep(2, 20, 0);
                return;
            }

            if (this.state.step === 2) {
                console.log('Going in here')
                console.log(this.state.basementAreaDontKnow);
                if (this.state.subStep === 0 && this.state.basementAreaDontKnow === 'Yes') {
                    this.changeStep(2, 30, 1);
                    return;
                } else {
                    this.changeStep(3, 40);
                    return;
                }
            }

            if (this.state.step === 3) {
                this.changeStep(4, 50);
                return;
            }

            if (this.state.step === 4) {
                this.changeStep(5, 60, 0);
                return;
            }

            if (this.state.step === 5) {

                if (this.state.subStep === 0) {
                    this.changeStep(5, 70, 1);
                    return;
                }

                if (this.state.subStep === 1) {
                    if (this.state.basementHasBathroom === 'Yes') {
                        this.changeStep(5, 75, 2);
                        return;
                    } else {
                        this.changeStep(99, 90);
                        return;
                    }
                }

                if (this.state.subStep === 2 ) {
                    if (this.state.basementBathroomRenovationNeeded === 'Yes') {
                        this.changeStep(5, 80, 3);
                    } else {
                        this.changeStep(99, 90);
                    }
                    return;
                }

                if (this.state.subStep === 3) {
                    //  &&(this.state.plumbingFixtureExistingBasement === '3piece' ||
                    //     this.state.plumbingFixtureNewBasement === '3piece') &&
                    // (this.state.plumbingFixtureNewBasement === 'Walk-in Shower' ||
                    //     this.state.plumbingFixtureExistingBasement === 'Walk-in Shower'))

                    if (this.state.bathroomConfig === 'Unfinished') {
                        this.changeStep(99, 90);
                        return;
                    } else {
                        if (this.state.plumbingFixtureExistingBasement === '2piece') {
                            this.changeStep(5, 80, 4);
                            return;
                        } else {
                            this.changeStep(5, 80, 5);
                            return;
                        }
                    }

                }

                if (this.state.subStep === 4) {
                    if (this.state.plumbingFixtureExistingBasement === '2piece') {
                        this.changeStep(99, 90);
                        return;
                    } else {
                        this.changeStep(5, 80, 5);
                        return;
                    }
                }

                if (this.state.subStep === 5) {
                    this.changeStep(99, 90);
                    return;
                }

            }

        } else {

            if (this.state.step === 2) {

                if (this.state.subStep === 1) {
                    this.changeStep(2, 20, 0);
                    return;
                }

                if (this.state.subStep === 0) {
                    this.changeStep(1, 15, 0);
                    return;
                }
            }

            if (this.state.step === 3) {
                if (this.state.basementAreaDontKnow === 'Yes') {
                    this.changeStep(2, 30, 1);
                    return;
                } else {
                    this.changeStep(2, 20, 0);
                    return;
                }
            }

            if (this.state.step === 4) {
                this.changeStep(3, 40);
                return;
            }

            if (this.state.step === 5) {
                if (this.state.subStep === 0) {
                    this.changeStep(4, 50);
                    return;
                }

                if (this.state.subStep === 1) {
                    this.changeStep(5, 60);
                    return;
                }

                if (this.state.subStep === 2) {
                    this.changeStep(5, 70, 1);
                    return;
                }

                if (this.state.subStep === 3) {
                    this.changeStep(5, 75, 2);
                    return;
                }

                if (this.state.subStep === 4) {
                    this.changeStep(5, 80, 3);
                    return;
                }

                if (this.state.subStep === 5) {

                    if (this.state.bathroomConfig === 'Unfinished') {
                        this.changeStep(5, 80, 3);
                        return;
                    } else {
                        if (this.state.plumbingFixtureExistingBasement === '2piece') {
                            this.changeStep(5, 80, 4);
                            return;
                        } else {
                            this.changeStep(5, 80, 3);
                            return;
                        }
                    }
                }
            }

            if (this.state.step === 99) {
                if (this.state.basementHasBathroom === 'Yes') {
                    if (this.state.basementBathroomRenovationNeeded === 'Yes') {
                        if (this.state.bathroomConfig === 'Unfinished') {
                            this.changeStep(5, 80, 3);
                        } else {
                            if (this.state.plumbingFixtureExistingBasement === '3piece') {
                                this.changeStep(5, 80, 5);
                            } else {
                                this.changeStep(5, 80, 4);
                            }
                        }
                    } else {
                        this.changeStep(5, 70, 2);
                    }
                } else {
                    this.changeStep(5, 70, 1);
                }

                return;
            }
        }
    }

    changeStepWithAnimation(stepType) {

        this.initializeStepAnimation();

        if (stepType === 'next') {
            window.setTimeout(() => {
                this.setState({
                    backButtonPressed: false,
                });

                if (this.state.renovationType === 'Bathroom') {
                    this.changeStepBathroom(stepType);
                } else if (this.state.renovationType === 'Basement') {
                    this.changeStepBasement(stepType);
                }
            }, 750);
        } else {
            window.setTimeout(() => {
                this.setState({
                    backButtonPressed: true,
                });

                if (this.state.renovationType === 'Bathroom') {
                    this.changeStepBathroom(stepType);
                } else if (this.state.renovationType === 'Basement') {
                    this.changeStepBasement(stepType);
                }

            }, 750);
        }

        window.setTimeout(() => this.resetAnimation(), 750);
    }

    render() {
        return (
            <div className="instant-quote-container">
                <div className="instant-quote__nav">
                    <div className="container-fluid">
                        <div className="row justify-content-space-between align-items-center">
                            <div className="col-md col d-flex align-items-center">
                                <div className="burger js-burger">
                                    <span className="burger__single"></span>
                                    <span className="burger__single"></span>
                                    <span className="burger__single"></span>
                                </div>
                                <img src="/resources/front/img/elfarro-logo.png" width={120} alt="Elfarro Logo"/>
                            </div>

                            <div className="col-md col d-flex">

                            </div>

                            <div className="col-md col d-flex justify-content-end">
                                <p>
                                    Get an Instant Quote<sup>TM</sup>
                                </p>
                            </div>
                        </div>
                    </div>
                    <ProgressBar progress={this.state.progressPercentage} />
                </div>

                <div className="container-fluid instant-quote__step-details">
                    <div className="row">
                        <div className="col-md col">
                            <p className="instant-quote__back-button" onClick={() => this.changeStepWithAnimation('previous')}>
                                <a href="#">
                                    <img src="/resources/front/img/icon-arrow-left.png" width={16} height={16} alt=""/> Back
                                </a>
                            </p>
                        </div>
                        <div className="col-md col text-right">
                            {this.state.step === 99 ? (
                                <p className="text-color-blue">Almost done!</p>
                            ) : (
                                <p>{this.state.step}/5</p>
                            )}
                        </div>
                    </div>
                </div>

                <AnimatedStepBox className="box" pose={this.state.stepIsVisible ? 'visible' : 'hidden'}>
                    {this.state.step === 1 && (
                        <Step1
                            renovationType={this.state.renovationType}
                            modifyData={this.modifyData}
                        />
                    )}

                    {this.state.renovationType === 'Bathroom' && (
                        <React.Fragment>
                            {this.state.step === 2 && (
                                <Step2Bathroom
                                    modifyData={this.modifyData}
                                    existingBathroom={this.state.existingBathroom}
                                    ensuiteBathroom={this.state.ensuiteBathroom}
                                    unfinishedBasement={this.state.unfinishedBasement}
                                />
                            )}

                            {this.state.step === 3 && (
                                <Step3Bathroom
                                    modifyData={this.modifyData}
                                    existingBathroom={this.state.existingBathroom}
                                    ensuiteBathroom={this.state.ensuiteBathroom}
                                    unfinishedBasement={this.state.unfinishedBasement}
                                    plumbingFixtureNew={this.state.plumbingFixtureNew}
                                    additionalPlumbingFixture4Piece={this.state.additionalPlumbingFixture4Piece}

                                    plumbingFixtureExisting={this.state.plumbingFixtureExisting}
                                    additionalPlumbingFixture4PieceExisting={this.state.additionalPlumbingFixture4PieceExisting}
                                    addAdditionalPlumbingFixtures={this.state.addAdditionalPlumbingFixtures}
                                    addAdditionalPlumbingFixturesSelected={this.state.addAdditionalPlumbingFixturesSelected}
                                />
                            )}

                            {this.state.step === 4 && this.state.subStep === 0 && (
                                <Step4Bathroom
                                    modifyData={this.modifyData}
                                    existingBathroom={this.state.existingBathroom}
                                    ensuiteBathroom={this.state.ensuiteBathroom}
                                    unfinishedBasement={this.state.unfinishedBasement}
                                    plumbingFixtureExisting={this.state.plumbingFixtureExisting}
                                    additionalPlumbingFixtureExisting={this.state.additionalPlumbingFixtureExisting}
                                    additionalPlumbingFixture4PieceExisting={this.state.additionalPlumbingFixture4PieceExisting}
                                    addAdditionalPlumbingFixtures={this.state.addAdditionalPlumbingFixtures}
                                    addAdditionalPlumbingFixturesSelected={this.state.addAdditionalPlumbingFixturesSelected}
                                    addAdditionalPlumbingFixturesSelectedNew={this.state.addAdditionalPlumbingFixturesSelectedNew}
                                />
                            )}

                            {this.state.step === 4 && this.state.subStep === 1 && (
                                <Step41Bathroom
                                    modifyData={this.modifyData}
                                    existingBathroom={this.state.existingBathroom}
                                    ensuiteBathroom={this.state.ensuiteBathroom}
                                    unfinishedBasement={this.state.unfinishedBasement}
                                    plumbingFixtureExisting={this.state.plumbingFixtureExisting}
                                    additionalPlumbingFixtureExisting={this.state.additionalPlumbingFixtureExisting}
                                    additionalPlumbingFixture4PieceExisting={this.state.additionalPlumbingFixture4PieceExisting}
                                    addAdditionalPlumbingFixtures={this.state.addAdditionalPlumbingFixtures}
                                    addAdditionalPlumbingFixturesSelected={this.state.addAdditionalPlumbingFixturesSelected}
                                    removeExistingPlumbingFixture={this.state.removeExistingPlumbingFixture}
                                    removeExistingPlumbingFixturesSelected={this.state.removeExistingPlumbingFixturesSelected}
                                />
                            )}

                            {this.state.step === 5 && (
                                <Step5Bathroom
                                    modifyData={this.modifyData}
                                    existingBathroom={this.state.existingBathroom}
                                    ensuiteBathroom={this.state.ensuiteBathroom}
                                    unfinishedBasement={this.state.unfinishedBasement}
                                    plumbingFixtureExisting={this.state.plumbingFixtureExisting}
                                    additionalPlumbingFixture4PieceExisting={this.state.additionalPlumbingFixture4PieceExisting}
                                    addAdditionalPlumbingFixtures={this.state.addAdditionalPlumbingFixtures}
                                    addAdditionalPlumbingFixturesSelected={this.state.addAdditionalPlumbingFixturesSelected}
                                    materialFinish={this.state.materialFinish}
                                />
                            )}
                        </React.Fragment>
                    )}

                    {this.state.renovationType === 'Basement' && (
                        <React.Fragment>
                            {this.state.step === 2 && this.state.subStep === 0 && (
                                <Step2Basement
                                    modifyData={this.modifyData}
                                    basementAreaDontKnow={this.state.basementAreaDontKnow}
                                    basementArea={this.state.basementArea}
                                />
                            )}

                            {this.state.step === 2 && this.state.subStep === 1 && (
                                <Step21Basement
                                    modifyData={this.modifyData}
                                    basementAreaDontKnow={this.state.basementAreaDontKnow}
                                    basementAreaGuess={this.state.basementAreaGuess}
                                />
                            )}

                            {this.state.step === 3 && (
                                <Step3Basement
                                    modifyData={this.modifyData}
                                    basementArea={this.state.basementArea}
                                    basementAreaDontKnow={this.state.basementAreaDontKnow}
                                    basementAreaGuess={this.state.basementAreaGuess}
                                    basementUnfinished={this.state.basementUnfinished}
                                />
                            )}

                            {this.state.step === 4 && (
                                <Step4Basement
                                    modifyData={this.modifyData}
                                    basementArea={this.state.basementArea}
                                    basementAreaDontKnow={this.state.basementAreaDontKnow}
                                    basementAreaGuess={this.state.basementAreaGuess}
                                    basementUnfinished={this.state.basementUnfinished}
                                    basementFireplace={this.state.basementFireplace}
                                    basementEgress={this.state.basementEgress}
                                />
                            )}

                            {this.state.step === 5 && this.state.subStep === 0 && (
                                <Step5Basement
                                    modifyData={this.modifyData}
                                    basementArea={this.state.basementArea}
                                    basementAreaDontKnow={this.state.basementAreaDontKnow}
                                    basementAreaGuess={this.state.basementAreaGuess}
                                    basementUnfinished={this.state.basementUnfinished}
                                    basementFireplace={this.state.basementFireplace}
                                    basementEgress={this.state.basementEgress}
                                    materialFinishBasement={this.state.materialFinishBasement}
                                />
                            )}

                            {this.state.step === 5 && this.state.subStep === 1 && (
                                <Step51Basement
                                    modifyData={this.modifyData}
                                    basementArea={this.state.basementArea}
                                    basementAreaDontKnow={this.state.basementAreaDontKnow}
                                    basementAreaGuess={this.state.basementAreaGuess}
                                    basementUnfinished={this.state.basementUnfinished}
                                    basementFireplace={this.state.basementFireplace}
                                    basementEgress={this.state.basementEgress}
                                    materialFinishBasement={this.state.materialFinishBasement}
                                    basementHasBathroom={this.state.basementHasBathroom}
                                    bathroomConfig={this.state.bathroomConfig}
                                />
                            )}

                            {this.state.step === 5 && this.state.subStep === 2 && (
                                <Step52Basement
                                    modifyData={this.modifyData}
                                    basementArea={this.state.basementArea}
                                    basementAreaDontKnow={this.state.basementAreaDontKnow}
                                    basementAreaGuess={this.state.basementAreaGuess}
                                    basementUnfinished={this.state.basementUnfinished}
                                    basementFireplace={this.state.basementFireplace}
                                    basementEgress={this.state.basementEgress}
                                    materialFinishBasement={this.state.materialFinishBasement}
                                    basementHasBathroom={this.state.basementHasBathroom}
                                    bathroomConfig={this.state.bathroomConfig}
                                    basementBathroomRenovationNeeded={this.state.basementBathroomRenovationNeeded}
                                />
                            )}


                            {/* Basement with bathroom */}
                            {this.state.step === 5 && this.state.subStep === 3 && (
                                <Step53Basement
                                    modifyData={this.modifyData}

                                    bathroomConfig={this.state.bathroomConfig}

                                    plumbingFixtureNewBasement={this.state.plumbingFixtureNewBasement}
                                    additionalPlumbingFixtureNewBasement={this.state.additionalPlumbingFixtureNewBasement}
                                    plumbingFixtureExistingBasement={this.state.plumbingFixtureExistingBasement}
                                    additionalPlumbingFixtureExistingBasement={this.state.additionalPlumbingFixtureExistingBasement}
                                />
                            )}

                            {this.state.step === 5 && this.state.subStep === 4 && (
                                <Step54Basement
                                    modifyData={this.modifyData}

                                    plumbingFixtureNewBasement={this.state.plumbingFixtureNewBasement}
                                    additionalPlumbingFixtureNewBasement={this.state.additionalPlumbingFixtureNewBasement}
                                    plumbingFixtureExistingBasement={this.state.plumbingFixtureExistingBasement}
                                    additionalPlumbingFixtureExistingBasement={this.state.additionalPlumbingFixtureExistingBasement}

                                    addAdditionalPlumbingFixturesSelectedNewBasement={this.state.addAdditionalPlumbingFixturesSelectedNewBasement}
                                    addAdditionalPlumbingFixturesBasement={this.state.addAdditionalPlumbingFixturesBasement}
                                    addAdditionalPlumbingFixturesSelectedBasement={this.state.addAdditionalPlumbingFixturesSelectedBasement}
                                />
                            )}


                            {this.state.step === 5 && this.state.subStep === 5 && (
                                <Step55Basement
                                    modifyData={this.modifyData}

                                    plumbingFixtureNewBasement={this.state.plumbingFixtureNewBasement}
                                    additionalPlumbingFixtureNewBasement={this.state.additionalPlumbingFixtureNewBasement}
                                    plumbingFixtureExistingBasement={this.state.plumbingFixtureExistingBasement}
                                    additionalPlumbingFixtureExistingBasement={this.state.additionalPlumbingFixtureExistingBasement}

                                    addAdditionalPlumbingFixturesBathroom={this.state.addAdditionalPlumbingFixturesBathroom}
                                    addAdditionalPlumbingFixturesSelectedBathroom={this.state.addAdditionalPlumbingFixturesSelectedBathroom}

                                    removeExistingPlumbingFixtureBasement={this.state.removeExistingPlumbingFixtureBasement}
                                    removeExistingPlumbingFixture3pieceBasement={this.state.removeExistingPlumbingFixture3pieceBasement}
                                />
                            )}

                        </React.Fragment>
                    )}


                    {this.state.step === 99 && (
                        <StepDetails
                            modifyData={this.modifyData}
                            existingBathroom={this.state.existingBathroom}
                            ensuiteBathroom={this.state.ensuiteBathroom}
                            unfinishedBasement={this.state.unfinishedBasement}
                            plumbingFixtureExisting={this.state.plumbingFixtureExisting}
                            additionalPlumbingFixture4PieceExisting={this.state.additionalPlumbingFixture4PieceExisting}
                            addAdditionalPlumbingFixtures={this.state.addAdditionalPlumbingFixtures}
                            addAdditionalPlumbingFixturesSelected={this.state.addAdditionalPlumbingFixturesSelected}
                            materialFinish={this.state.materialFinish}
                        />
                    )}
                </AnimatedStepBox>

                <div className="instant-quote__next-container">
                    <button disabled={this.state.loadingIndicatorVisible} className="instant-quote__next-button" onClick={() => this.changeStepWithAnimation('next', 'basement')}>
                        <span className="instant-quote__next-button__loader-container" style={{ opacity: this.state.loadingIndicatorVisible ? 1 : 0 }}>
                            <img className="instant-quote__next-button__loader" src="/resources/front/img/spinner-white-24.png" alt="" />
                        </span>
                        <span style={{ opacity: this.state.loadingIndicatorVisible ? 0 : 1 }}>
                            {this.state.step === 99 ? `See my Instant Quote™` : 'Next step'}
                            <img src="/resources/front/img/Elfarro-Right-Arrow-White.svg" className="instant-quote__next-button__arrow" />
                        </span>
                    </button>
                </div>
            </div>
        )
    }
}

export default InstantQuote;