import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step3(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title main-title--no-subtitle">Is your basement unfinished?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12">
                    <label for="basementUnfinished" className="FancyInput FancyInput--first">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            id="basementUnfinished"
                            value="yes"
                            defaultChecked={props.basementUnfinished === 'Yes'}
                            checked={props.basementUnfinished === 'Yes'}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('basementUnfinished', 'Yes')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Yes</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Typically left untouched by the homebuilder</p>
                                </div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md-6 col-12">
                    <label htmlFor="basementUnfinished" className="FancyInput FancyInput--last">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            id="basementUnfinished"
                            value="no"
                            defaultChecked={props.basementUnfinished === 'No'}
                            checked={props.basementUnfinished === 'No'}
                        />
                        <div className="FancyInput__Border"
                             onClick={() => props.modifyData('basementUnfinished', 'No')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">No</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Basement is already finished and needs to be remodelled</p>
                                </div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    )

}