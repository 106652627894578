import React from 'react';

export default function Step21(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title main-title--no-subtitle">How big is your basement?</h1>
                </div>
            </div>

            <div className="row">

                <div className="col-md-6 col-12">
                    <label for="basementAreaGuess" className="FancyInput FancyInput--first">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            id="basementAreaGuess"
                            value="no"
                            defaultChecked={props.basementAreaGuess === 'Townhome'}
                            checked={props.basementAreaGuess === 'Townhome'}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('basementAreaGuess', 'Townhome')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Townhome</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Typically 300 ft<sup>2</sup> to 500 ft<sup>2</sup> basements</p>
                                </div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md-6 col-12">
                    <label htmlFor="basementAreaGuess" className="FancyInput">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                id="basementAreaGuess"
                                value="no"
                                defaultChecked={props.basementAreaGuess === 'Semi-detached Home'}
                                checked={props.basementAreaGuess === 'Semi-detached Home'}
                            />
                            <div className="FancyInput__Border"
                                 onClick={() => props.modifyData('basementAreaGuess', 'Semi-detached Home')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">Semi-detached Home</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Typically 500 ft<sup>2</sup> to 700 ft<sup>2</sup> basements</p>
                                    </div>
                                    <span className="custom-radio">
                                        <span className="custom-radio__inner"></span>
                                    </span>
                                </div>
                            </div>
                        </label>
                    </div>

                    <div className="col-md-6 col-12">
                        <label htmlFor="basementAreaGuess" className="FancyInput">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                id="basementAreaGuess"
                                value="no"
                                defaultChecked={props.basementAreaGuess === 'Single Family Home'}
                                checked={props.basementAreaGuess === 'Single Family Home'}
                            />
                            <div className="FancyInput__Border"
                                 onClick={() => props.modifyData('basementAreaGuess', 'Single Family Home')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">Single Family Home</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Typically 700 ft<sup>2</sup> to 900 ft<sup>2</sup> basements</p>
                                    </div>
                                    <span className="custom-radio">
                                            <span className="custom-radio__inner"></span>
                                        </span>
                                </div>
                            </div>
                        </label>
                    </div>

                    <div className="col-md-6 col-12">
                        <label htmlFor="basementAreaGuess" className="FancyInput">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                id="basementAreaGuess"
                                value="no"
                                defaultChecked={props.basementAreaGuess === 'Bungalow'}
                                checked={props.basementAreaGuess === 'Bungalow'}
                            />
                            <div className="FancyInput__Border"
                                 onClick={() => props.modifyData('basementAreaGuess', 'Bungalow')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">Bungalow</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Typically 900 ft<sup>2</sup> to 1,100 ft<sup>2</sup> basements or more</p>
                                    </div>
                                    <span className="custom-radio">
                                        <span className="custom-radio__inner"></span>
                                    </span>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
    )

}