import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step53(props) {
    if (props.bathroomConfig === 'Existing') {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md col">
                        <h1 className="main-title">How many plumbing fixtures does your bathroom currently have?</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-6">
                        <label className="FancyInput FancyInput--first">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="plumbingFixtureExistingBasement"
                                defaultChecked={props.plumbingFixtureExistingBasement === '2piece'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureExistingBasement', '2piece')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">Powder Room</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet</p>
                                    </div>
                                    <div>
                                        <span className="custom-radio">
                                            <span className="custom-radio__inner"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="col-12 col-md-6">
                        <label className={`FancyInput FancyInput--last`}>
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="plumbingFixtureExistingBasement"
                                defaultChecked={props.plumbingFixtureExistingBasement === '3piece'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureExistingBasement', '3piece')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">3-piece Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet, plus one other fixture</p>
                                    </div>
                                    <div>
                                    <span className="custom-radio">
                                        <span className="custom-radio__inner"></span>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>

                </div>

                <PoseGroup>
                    {props.plumbingFixtureExistingBasement === "3piece" && (
                        <AnimatedBox key="plumbingExisting3PieceBasement">
                            <div className="row">
                                <div className="col col-md">
                                    <div className="FancyInputArea__section-heading">
                                        <p>Existing plumbing fixture</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <label className="FancyInput FancyInput--first">
                                        <input
                                            type="radio"
                                            className="FancyInput__radio-button"
                                            name="additionalPlumbingFixtureExistingBasement"
                                            defaultChecked={props.additionalPlumbingFixtureExistingBasement === 'Bathtub'}
                                        />

                                        <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixtureExistingBasement', 'Bathtub')}>
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Bathtub</p>
                                                </div>
                                                <div>
                                                <span className="custom-radio">
                                                    <span className="custom-radio__inner"></span>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="col-md-12 col-12">
                                    <label className="FancyInput">
                                        <input
                                            type="radio"
                                            className="FancyInput__radio-button"
                                            name="additionalPlumbingFixtureExistingBasement"
                                            defaultChecked={props.additionalPlumbingFixtureExistingBasement === 'Walk-in Shower'}
                                        />
                                        <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixtureExistingBasement', 'Walk-in Shower')}>
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Walk-in Shower</p>
                                                </div>
                                                <div>
                                                <span className="custom-radio">
                                                    <span className="custom-radio__inner"></span>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </AnimatedBox>
                    )}
                </PoseGroup>
            </div>
        )
    } else {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md col">
                        <h1 className="main-title">How many plumbing fixtures will your new bathroom have?</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md">
                        <label className="FancyInput FancyInput--first">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="plumbingFixtureNewBasement"
                                defaultChecked={props.plumbingFixtureNewBasement === '2piece'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureNewBasement', '2piece')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">Powder Room</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet</p>
                                    </div>
                                    <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>

                    <div className="col-12 col-md">
                        <label className={`FancyInput FancyInput--last`}>
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="plumbingFixtureNewBasement"
                                defaultChecked={props.plumbingFixtureNewBasement === '3piece'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureNewBasement', '3piece')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">3-piece Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet, plus one other fixture</p>
                                    </div>
                                    <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>

                </div>

                {props.plumbingFixtureNewBasement === "3piece" && (
                    <React.Fragment>
                        <div className="row">
                            <div className="col col-md">
                                <div className="FancyInputArea__section-heading">
                                    <p>Additional plumbing fixture</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12">
                                <label className="FancyInput FancyInput--first">
                                    <input
                                        type="radio"
                                        className="FancyInput__radio-button"
                                        name="additionalPlumbingFixtureBasement"
                                        defaultChecked={props.additionalPlumbingFixtureNewBasement === 'Bathtub'}
                                    />

                                    <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixtureNewBasement', 'Bathtub')}>
                                        <div className="FancyInput__Text">
                                            <div>
                                                <p className="FancyInput__text__title FancyInput__Text__title--dark">Bathtub</p>
                                            </div>
                                            <div>
                                            <span className="custom-radio">
                                                <span className="custom-radio__inner"></span>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className="col-md-12 col-12">
                                <label className="FancyInput">
                                    <input
                                        type="radio"
                                        className="FancyInput__radio-button"
                                        name="additionalPlumbingFixtureBasement"
                                        defaultChecked={props.additionalPlumbingFixtureNewBasement === 'Walk-in Shower'}

                                    />
                                    <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixtureNewBasement', 'Walk-in Shower')}>
                                        <div className="FancyInput__Text">
                                            <div>
                                                <p className="FancyInput__text__title FancyInput__Text__title--dark">Walk-in Shower</p>
                                            </div>
                                            <div>
                                            <span className="custom-radio">
                                                <span className="custom-radio__inner"></span>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </React.Fragment>
                )}

            </div>
        )
    }
}