import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step55(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title main-title--no-subtitle">Do you want to remove any existing plumbing fixtures?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12">
                    <label className="FancyInput">
                        <input type="radio" className="FancyInput__radio-button" name="removeExistingPlumbingFixtureBasement" value="yes" checked={props.removeExistingPlumbingFixtureBasement === 'Yes'} />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('removeExistingPlumbingFixtureBasement', 'Yes')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Yes</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md-6 col-12">
                    <label className="FancyInput FancyInput--last">
                        <input type="radio" className="FancyInput__radio-button" name="removeExistingPlumbingFixtureBasement" value="no" checked={props.removeExistingPlumbingFixtureBasement === 'No'} />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('removeExistingPlumbingFixtureBasement', 'No')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">No</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <PoseGroup>
                {props.removeExistingPlumbingFixtureBasement === "Yes"  && (
                    <AnimatedBox key='removeExistingPlumbingFixturesBasement'>
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="FancyInputArea__section-heading">
                                    <p>Remove Existing Plumbing Fixture</p>
                                </div>
                            </div>
                        </div>

                        {props.plumbingFixtureExistingBasement === '3piece' && (
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <label className="FancyInput FancyInput--first">
                                        <input
                                            type="checkbox"
                                            className="FancyInput__radio-button"
                                            name="removeExistingPlumbingFixturesSelected"
                                            defaultChecked={props.removeExistingPlumbingFixture3pieceBasement === props.additionalPlumbingFixtureExistingBasement}
                                        />

                                        <div
                                            className="FancyInput__Border"
                                             onClick={() => props.modifyData('removeExistingPlumbingFixture3pieceBasement',
                                                 props.removeExistingPlumbingFixture3pieceBasement.length > 0 ? '' : props.additionalPlumbingFixtureExistingBasement)}
                                        >
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">{props.additionalPlumbingFixtureExistingBasement}</p>
                                                </div>
                                                <div>
                                                    <span className="custom-checkbox">
                                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        )}

                    </AnimatedBox>
                )}
            </PoseGroup>
        </div>
    )

}