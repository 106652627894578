import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step4(props) {

    let plumbingFixturesRemainingToAdd = [];

    if (props.plumbingFixtureExisting === '4piece') {
        plumbingFixturesRemainingToAdd = ['Walk-in Shower', 'Bathtub', 'Double Sinks'].filter(e => e !== props.additionalPlumbingFixture4PieceExisting.find(f => f === e));
        console.log(['Walk-in Shower', 'Bathtub', 'Double Sinks'].filter(e => e !== props.additionalPlumbingFixture4PieceExisting.find(f => f === e)));
    } else if (props.plumbingFixtureExisting === '3piece') {
        plumbingFixturesRemainingToAdd = ['Walk-in Shower', 'Bathtub', 'Double Sinks'].filter(e => e !== props.additionalPlumbingFixtureExisting);
        console.log(['Walk-in Shower', 'Bathtub', 'Double Sinks'].filter(e => e !== props.additionalPlumbingFixtureExisting));
    }

    console.log(plumbingFixturesRemainingToAdd);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-12">
                    <h1 className="main-title main-title--no-subtitle">Do you want to add additional plumbing fixtures?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12">
                    <label className="FancyInput">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                           name="additionalPlumbingFixture"
                           value="yes"
                           checked={props.addAdditionalPlumbingFixtures === 'Yes'}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('addAdditionalPlumbingFixtures', 'Yes')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Yes</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md-6 col-12">
                    <label className="FancyInput FancyInput--last">
                        <input type="radio" className="FancyInput__radio-button" name="addAdditionalPlumbingFixtures" value="no" checked={props.addAdditionalPlumbingFixtures === 'No'} />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('addAdditionalPlumbingFixtures', 'No')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">No</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <PoseGroup>
                {props.addAdditionalPlumbingFixtures === "Yes"  && (
                    <AnimatedBox key='additionalPlumbingFixtures'>
                        <div className="row">
                            <div className="col col-md">
                                <div className="FancyInputArea__section-heading">
                                    <p>Additional Plumbing Fixture</p>
                                </div>
                            </div>
                        </div>

                        {props.plumbingFixtureExisting === '3piece' && (
                            <React.Fragment>
                                {plumbingFixturesRemainingToAdd.map((fixture, index) => (
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <label className={`FancyInput ${index === 0 ? 'FancyInput--first' : ''}`}>
                                                <input
                                                    type="checkbox"
                                                    className="FancyInput__radio-button"
                                                    name="removeExistingPlumbingFixturesSelected"
                                                    defaultChecked={props.addAdditionalPlumbingFixturesSelectedNew.indexOf(fixture) !== -1}
                                                />

                                                <div
                                                    className="FancyInput__Border"
                                                     onClick={() => props.modifyData('addAdditionalPlumbingFixturesSelectedNew', fixture, true, plumbingFixturesRemainingToAdd.length)}
                                                >
                                                    <div className="FancyInput__Text">
                                                        <div>
                                                            <p className="FancyInput__text__title FancyInput__Text__title--dark">{fixture}</p>
                                                        </div>
                                                        <div>
                                                    <span className="custom-checkbox">
                                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                    </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        )}

                        {props.plumbingFixtureExisting === '4piece' && (
                            <React.Fragment>
                                {plumbingFixturesRemainingToAdd.map((fixture, index) => (
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <label className={`FancyInput ${index === 0 ? 'FancyInput--first' : ''}`}>
                                                <input
                                                    type="checkbox"
                                                    className="FancyInput__radio-button"
                                                    name="removeExistingPlumbingFixturesSelected"
                                                    defaultChecked={props.addAdditionalPlumbingFixturesSelectedNew.indexOf(fixture) !== -1}
                                                />

                                                <div
                                                    className="FancyInput__Border"
                                                    onClick={() => props.modifyData('addAdditionalPlumbingFixturesSelectedNew', fixture, true, plumbingFixturesRemainingToAdd.length)}
                                                >
                                                    <div className="FancyInput__Text">
                                                        <div>
                                                            <p className="FancyInput__text__title FancyInput__Text__title--dark">{fixture}</p>
                                                        </div>
                                                        <div>
                                                    <span className="custom-checkbox">
                                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                    </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        )}

                        {props.plumbingFixtureExisting === '2piece' && (
                            <React.Fragment>
                                {['Walk-in Shower', 'Bathtub', 'Double Sinks'].map((fixture, index) => (
                                    <div className="row">
                                        <div className="col-md-12 col-12">
                                            <label className={`FancyInput ${index === 0 ? 'FancyInput--first' : ''}`}>
                                                <input
                                                    type="checkbox"
                                                    className="FancyInput__radio-button"
                                                    name="removeExistingPlumbingFixturesSelected"
                                                    defaultChecked={props.addAdditionalPlumbingFixturesSelectedNew.indexOf(fixture) !== -1}
                                                />

                                                <div
                                                    className="FancyInput__Border"
                                                    onClick={() => props.modifyData('addAdditionalPlumbingFixturesSelectedNew', fixture, true, 3)}
                                                >
                                                    <div className="FancyInput__Text">
                                                        <div>
                                                            <p className="FancyInput__text__title FancyInput__Text__title--dark">{fixture}</p>
                                                        </div>
                                                        <div>
                                                    <span className="custom-checkbox">
                                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                    </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        )}
                    </AnimatedBox>
                )}
            </PoseGroup>
        </div>
    )

}