import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import GetEstimateSmall from './components/GetEstimateSmall.jsx';
import InstantQuote from './components/InstantQuote/InstantQuote';
import autoBind from 'react-autobind';

class AppSmall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      onStep2: false,
    };

    this.fieldValues = {
      name: null,
      email: null,
      phone: null,
      renovationType: null,
      virtualMeet: 'no',
      budget: null,
      timeline: null,
      address: null,
      currentStep: null,
      step1Done: false,
      step2Done: false,
    };

    autoBind(this);
  }

  _setStep(newStep) {
    this.setState({ step: newStep });
  }

  _closeModal() {
    document
      .querySelector('.modal.modal--get-estimate')
      .classList.toggle('modal--is-active');
    document.body.classList.remove('modal--open');
    window.location.hash = '';

    if (this.fieldValues.step1Done && !this.fieldValues.step2Done) {
      axios
        .post('/signup-additional', {
          renovationType: this.fieldValues.renovationType,
          budget: this.fieldValues.budget,
          timeline: this.fieldValues.timeline,
          address: this.fieldValues.address,
          userEmail: this.fieldValues.email,
        })
        .then((response) => {
          console.log(response);
          // this.props.nextStep();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  _saveValues(fields) {
    // Remember, `fieldValues` is set at the top of this file, we are simply appending
    // to and overriding keys in `fieldValues` with the `fields` using Object.assign
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign

    this.fieldValues = Object.assign({}, this.fieldValues, fields);
  }

  renderStepBackground() {
    return <div className="get-estimate-left--step-one"></div>;
  }

  _setFirstStepAsCompleted() {
    this.setState({ state: 2 });
  }

  render() {
    return (
      <div
        className="modal fade modal--get-estimate d-flex align-items-center justify-content-between"
        id="get-estimate"
      >
        <div className="get-estimate-left">{this.renderStepBackground()}</div>

        <div className="get-estimate-right">
          <div className="modal-header">
            <div className="container-almost-full">
              <div className="row flex-row align-items-center justify-content-around">
                <div className="modal-get-estimate-logo">
                  <img
                    src="/resources/front/img/elfarro-design-build.svg"
                    width="200"
                  />
                </div>

                <div
                  className="get-estimate-close modal-close js-modal-close"
                  onClick={this._closeModal}
                ></div>
              </div>
            </div>
          </div>

          <div className="get-estimate-content">
            <GetEstimateSmall
              key={1}
              saveValuesParent={this._saveValues}
              setStep={this._setStep}
              _setFirstStepAsCompleted={this._setFirstStepAsCompleted}
              _setSecondStepAsCompleted={this._setSecondStepAsCompleted}
            />
          </div>

          <p></p>
        </div>
      </div>
    );
  }
}

ReactDOM.render(<AppSmall />, document.getElementById('render-modal'));

ReactDOM.render(<InstantQuote />, document.getElementById('render-modal-2'));
