import React from 'react';

export default function Step52(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title main-title--no-subtitle">Do you want to {props.bathroomConfig === 'Existing' ? 'remodel the existing' : 'finish the'} bathroom?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md col-12">
                    <label className="FancyInput FancyInput--first">
                        <input type="radio"
                               className="FancyInput__radio-button"
                               name="basementBathroomRenovationNeeded"
                               value="yes"
                               defaultChecked={props.basementBathroomRenovationNeeded === 'Yes'}
                               checked={props.basementBathroomRenovationNeeded === 'Yes'}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('basementBathroomRenovationNeeded', 'Yes')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Yes</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md col-12">
                    <label className="FancyInput FancyInput--last">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="basementBathroomRenovationNeeded"
                            value="no"
                            defaultChecked={props.basementBathroomRenovationNeeded === 'No'}
                            checked={props.basementBathroomRenovationNeeded === 'No'}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('basementBathroomRenovationNeeded', 'No')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">No</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    )

}