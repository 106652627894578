import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step21(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title main-title--no-subtitle">How big is your basement?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md">
                    <label htmlFor="basementArea" className={`FancyInput FancyInput--first FancyInput--input-text`}>
                        <input
                            type="text"
                            id="basementArea"
                            name="basementArea"
                            defaultValue={props.basementArea}
                            placeholder="Total basement area"
                            onChange={(e) => props.modifyData('basementArea', e.currentTarget.value)}
                        />
                        <div className="FancyInput__Border">
                        </div>
                        <span className="FancyInput--input-text__adornment">ft<sup>2</sup></span>

                    </label>
                </div>

                <div className="col-md col-12">
                    <label for="basementAreaDontKnow" className="FancyInput FancyInput--last">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            id="basementAreaDontKnow"
                            value="no"
                            defaultChecked={props.basementAreaDontKnow === 'Yes' && props.basementArea === ''}
                            checked={props.basementAreaDontKnow === 'Yes' && props.basementArea === ''}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('basementAreaDontKnow', 'Yes')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">I don't know</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <PoseGroup>
                <AnimatedBox key="existingBathroom">
                    <div className="row">
                        <div className="col col-md">
                            <div className="FancyInputArea__section-heading">
                                <p>Floor Plan Layout</p>
                            </div>
                        </div>
                    </div>

                    <label className="FancyInput FancyInput--first">
                        <input
                            type="checkbox"
                            className="FancyInput__radio-button"
                            name="laundryRoom"
                            defaultChecked={props.laundryRoom}
                            checked={props.laundryRoom}
                        />

                        <div className="FancyInput__Border" onClick={() => props.modifyData('laundryRoom', !props.laundryRoom)}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Laundry Room</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Laundry room is in the basement</p>
                                </div>
                                <div>
                                    <span className="custom-checkbox">
                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </AnimatedBox>
            </PoseGroup>
        </div>
    )

}