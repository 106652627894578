import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step3(props) {
    if (props.existingBathroom === 'Yes') {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md col">
                        <h1 className="main-title">How many plumbing fixtures does your bathroom currently have?</h1>
                    </div>
                </div>

                <div className="row">
                    {!props.ensuiteBathroom && (
                        <div className="col-12 col-md-6">
                            <label className="FancyInput FancyInput--first">
                                <input
                                    type="radio"
                                    className="FancyInput__radio-button"
                                    name="plumbingFixtureExisting"
                                    defaultChecked={props.plumbingFixtureExisting === '2piece'}
                                />

                                <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureExisting', '2piece')}>
                                    <div className="FancyInput__Text">
                                        <div>
                                            <p className="FancyInput__text__title FancyInput__Text__title--dark">Powder Room</p>
                                            <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet</p>
                                        </div>
                                        <div>
                                            <span className="custom-radio">
                                                <span className="custom-radio__inner"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    )}

                    <div className="col-12 col-md-6">
                        <label className={`FancyInput ${props.ensuiteBathroom && 'FancyInput--first'}`}>
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="plumbingFixtureExisting"
                                defaultChecked={props.plumbingFixtureExisting === '3piece'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureExisting', '3piece')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">3-piece Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet, plus one other fixture</p>
                                    </div>
                                    <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="FancyInput">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="plumbingFixtureExisting"
                                defaultChecked={props.plumbingFixtureExisting === '4piece'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureExisting', '4piece')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">4-piece Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet, plus two other fixtures</p>
                                    </div>
                                    <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="col-12 col-md-6">
                        <label className="FancyInput FancyInput--last">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="plumbingFixtureExisting"
                                defaultChecked={props.plumbingFixtureExisting === '5piece'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureExisting', '5piece')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">5-piece Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet, plus three other fixtures</p>
                                    </div>
                                    <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                <PoseGroup>
                    {props.plumbingFixtureExisting === "3piece" && (
                        <AnimatedBox key="plumbingExisting3Piece">
                            <div className="row">
                                <div className="col col-md">
                                    <div className="FancyInputArea__section-heading">
                                        <p>Existing plumbing fixture</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <label className="FancyInput FancyInput--first">
                                        <input
                                            type="radio"
                                            className="FancyInput__radio-button"
                                            name="additionalPlumbingFixtureExisting"
                                            defaultChecked={props.additionalPlumbingFixtureExisting === 'Bathtub'}
                                            // checked={props.additionalPlumbingFixtureExisting === 'Bathtub'}
                                        />

                                        <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixtureExisting', 'Bathtub')}>
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Bathtub</p>
                                                </div>
                                                <div>
                                                <span className="custom-radio">
                                                    <span className="custom-radio__inner"></span>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="col-md-12 col-12">
                                    <label className="FancyInput">
                                        <input
                                            type="radio"
                                            className="FancyInput__radio-button"
                                            name="additionalPlumbingFixtureExisting"
                                            defaultChecked={props.additionalPlumbingFixtureExisting === 'Walk-in Shower'}
                                            // checked={props.additionalPlumbingFixtureExisting === 'Walk-in Shower'}
                                        />
                                        <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixtureExisting', 'Walk-in Shower')}>
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Walk-in Shower</p>
                                                </div>
                                                <div>
                                                <span className="custom-radio">
                                                    <span className="custom-radio__inner"></span>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </AnimatedBox>
                    )}
                </PoseGroup>

                <PoseGroup>
                    {props.plumbingFixtureExisting === "4piece" && (
                        <AnimatedBox key="plumbingExisting4Piece">
                            <div className="row">
                                <div className="col col-md">
                                    <div className="FancyInputArea__section-heading">
                                        <p>Two Existing Plumbing Fixtures</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <label className="FancyInput FancyInput--first">
                                        <input
                                            type="checkbox"
                                            className="FancyInput__radio-button"
                                            name="additionalPlumbingFixture4PieceExisting"
                                            defaultChecked={props.additionalPlumbingFixture4PieceExisting.indexOf('Bathtub') !== -1}
                                            disabled={props.additionalPlumbingFixture4PieceExisting.indexOf('Bathtub') === -1 && props.additionalPlumbingFixture4PieceExisting.length === 2}
                                        />

                                        <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixture4PieceExisting', 'Bathtub', true, 2)}>
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Bathtub</p>
                                                </div>
                                                <div>
                                                    <span className="custom-checkbox">
                                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="col-md-12 col-12">
                                    <label className="FancyInput">
                                        <input
                                            type="checkbox"
                                            className="FancyInput__radio-button"
                                            name="additionalPlumbingFixture4Piece"
                                            defaultChecked={props.additionalPlumbingFixture4PieceExisting.indexOf('Walk-in Shower') !== -1}
                                            disabled={props.additionalPlumbingFixture4PieceExisting.indexOf('Walk-in Shower') === -1 && props.additionalPlumbingFixture4PieceExisting.length === 2}
                                        />
                                        <div
                                            className="FancyInput__Border"
                                            onClick={() => props.modifyData('additionalPlumbingFixture4PieceExisting', 'Walk-in Shower', true, 2)}
                                        >
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Walk-in Shower</p>
                                                </div>
                                                <div>
                                                <span className="custom-checkbox">
                                                    <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="col-md-12 col-12">
                                    <label className="FancyInput">
                                        <input
                                            type="checkbox"
                                            className="FancyInput__radio-button"
                                            name="additionalPlumbingFixture4Piece"
                                            defaultChecked={props.additionalPlumbingFixture4PieceExisting.indexOf('Double Sinks') !== -1}
                                            disabled={props.additionalPlumbingFixture4PieceExisting.indexOf('Double Sinks') === -1 && props.additionalPlumbingFixture4PieceExisting.length === 2}
                                        />
                                        <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixture4PieceExisting', 'Double Sinks', true, 2)}>
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Double Sinks</p>
                                                </div>
                                                <div>
                                                <span className="custom-checkbox">
                                                    <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </AnimatedBox>
                    )}
                </PoseGroup>

                <PoseGroup>
                    {props.plumbingFixtureExisting === "5piece" && (
                        <AnimatedBox key="plumbingExisting5Piece">
                            <div className="row">
                                <div className="col col-md">
                                    <div className="FancyInputArea__section-heading">
                                        <p>Three Existing Plumbing Fixtures</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <label className="FancyInput FancyInput--first">
                                        <input
                                            type="checkbox"
                                            className="FancyInput__radio-button FancyInput__radio-button--checked-disabled"
                                            checked
                                        />

                                        <div className="FancyInput__Border">
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Bathtub</p>
                                                </div>
                                                <div>
                                                <span className="custom-checkbox">
                                                    <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="col-md-6 col-12">
                                    <label className="FancyInput">
                                        <input
                                            type="checkbox"
                                            className="FancyInput__radio-button FancyInput__radio-button--checked-disabled"
                                            checked
                                        />
                                        <div className="FancyInput__Border">
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Walk-in Shower</p>
                                                </div>
                                                <div>
                                                <span className="custom-checkbox">
                                                    <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <div className="col-md-6 col-12">
                                    <label className="FancyInput">
                                        <input
                                            type="checkbox"
                                            className="FancyInput__radio-button FancyInput__radio-button--checked-disabled"
                                            checked
                                        />
                                        <div className="FancyInput__Border">
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Double Sinks</p>
                                                </div>
                                                <div>
                                                <span className="custom-checkbox">
                                                    <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </AnimatedBox>
                    )}
                </PoseGroup>
            </div>
        )
    } else {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md col">
                        <h1 className="main-title">How many plumbing fixtures will your new bathroom have?</h1>
                    </div>
                </div>

                <div className="row">
                    {props.ensuiteBathroom !== 'Yes' && (
                        <div className="col-12 col-md">
                            <label className="FancyInput FancyInput--first">
                                <input
                                    type="radio"
                                    className="FancyInput__radio-button"
                                    name="plumbingFixtureNew"
                                    defaultChecked={props.plumbingFixtureNew === '2piece'}
                                />

                                <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureNew', '2piece')}>
                                    <div className="FancyInput__Text">
                                        <div>
                                            <p className="FancyInput__text__title FancyInput__Text__title--dark">Powder Room</p>
                                            <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet</p>
                                        </div>
                                        <div>
                                    <span className="custom-radio">
                                        <span className="custom-radio__inner"></span>
                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    )}

                    <div className="col-12 col-md">
                        <label className={`FancyInput ${props.ensuiteBathroom === 'No' && 'FancyInput--first'}`}>
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="plumbingFixtureNew"
                                defaultChecked={props.plumbingFixtureNew === '3piece'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureNew', '3piece')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">3-piece Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet, plus one other fixture</p>
                                    </div>
                                    <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="col-12 col-md">
                        <label className="FancyInput">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="plumbingFixtureNew"
                                defaultChecked={props.plumbingFixtureNew === '4piece'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureNew', '4piece')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">4-piece Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet, plus two other fixtures</p>
                                    </div>
                                    <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className="col-12 col-md">
                        <label className="FancyInput FancyInput--last">
                            <input
                                type="radio"
                                className="FancyInput__radio-button"
                                name="plumbingFixtureNew"
                                defaultChecked={props.plumbingFixtureNew === '5piece'}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('plumbingFixtureNew', '5piece')}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">5-piece Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Sink and a toilet, plus three other fixtures</p>
                                    </div>
                                    <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>

                {props.plumbingFixtureNew === "3piece" && (
                    <React.Fragment>
                        <div className="row">
                            <div className="col col-md">
                                <div className="FancyInputArea__section-heading">
                                    <p>Additional plumbing fixture</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12">
                                <label className="FancyInput FancyInput--first">
                                    <input
                                        type="radio"
                                        className="FancyInput__radio-button"
                                        name="additionalPlumbingFixture"
                                        defaultChecked={props.additionalPlumbingFixture === 'Bathtub'}
                                    />

                                    <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixture', 'Bathtub')}>
                                        <div className="FancyInput__Text">
                                            <div>
                                                <p className="FancyInput__text__title FancyInput__Text__title--dark">Bathtub</p>
                                            </div>
                                            <div>
                                            <span className="custom-radio">
                                                <span className="custom-radio__inner"></span>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className="col-md-12 col-12">
                                <label className="FancyInput">
                                    <input
                                        type="radio"
                                        className="FancyInput__radio-button"
                                        name="additionalPlumbingFixture"
                                        defaultChecked={props.additionalPlumbingFixture === 'Walk-in Shower'}

                                    />
                                    <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixture', 'Walk-in Shower')}>
                                        <div className="FancyInput__Text">
                                            <div>
                                                <p className="FancyInput__text__title FancyInput__Text__title--dark">Walk-in Shower</p>
                                            </div>
                                            <div>
                                            <span className="custom-radio">
                                                <span className="custom-radio__inner"></span>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>


                    </React.Fragment>
                )}

                {props.plumbingFixtureNew === "4piece" && (
                    <React.Fragment>
                        <div className="row">
                            <div className="col col-md">
                                <div className="FancyInputArea__section-heading">
                                    <p>Two Additional Plumbing Fixtures</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12">
                                <label className="FancyInput FancyInput--first">
                                    <input
                                        type="checkbox"
                                        className="FancyInput__radio-button"
                                        name="additionalPlumbingFixture4Piece"
                                        defaultChecked={props.additionalPlumbingFixture4Piece.indexOf('Bathtub') !== -1}
                                        disabled={props.additionalPlumbingFixture4Piece.indexOf('Bathtub') === -1 &&
                                        props.additionalPlumbingFixture4Piece.length === 2}
                                    />

                                    <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixture4Piece', 'Bathtub', true, 2)}>
                                        <div className="FancyInput__Text">
                                            <div>
                                                <p className="FancyInput__text__title FancyInput__Text__title--dark">Bathtub</p>
                                            </div>
                                            <div>
                                            <span className="custom-checkbox">
                                                <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className="col-md-12 col-12">
                                <label className="FancyInput">
                                    <input
                                        type="checkbox"
                                        className="FancyInput__radio-button"
                                        name="additionalPlumbingFixture4Piece"
                                        defaultChecked={props.additionalPlumbingFixture4Piece.indexOf('Walk-in Shower') !== -1}
                                        disabled={props.additionalPlumbingFixture4Piece.indexOf('Walk-in Shower') === -1 &&
                                        props.additionalPlumbingFixture4Piece.length === 2}
                                    />
                                    <div
                                        className="FancyInput__Border"
                                        onClick={() => props.modifyData('additionalPlumbingFixture4Piece', 'Walk-in Shower', true, 2)}
                                    >
                                        <div className="FancyInput__Text">
                                            <div>
                                                <p className="FancyInput__text__title FancyInput__Text__title--dark">Walk-in Shower</p>
                                            </div>
                                            <div>
                                            <span className="custom-checkbox">
                                                <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className="col-md-12 col-12">
                                <label className="FancyInput">
                                    <input
                                        type="checkbox"
                                        className="FancyInput__radio-button"
                                        name="additionalPlumbingFixture4Piece"
                                        defaultChecked={props.additionalPlumbingFixture4Piece.indexOf('Double Sinks') !== -1}
                                        disabled={props.additionalPlumbingFixture4Piece.indexOf('Double Sinks') === -1 &&
                                        props.additionalPlumbingFixture4Piece.length === 2}
                                    />
                                    <div className="FancyInput__Border" onClick={() => props.modifyData('additionalPlumbingFixture4Piece', 'Double Sinks', true, 2)}>
                                        <div className="FancyInput__Text">
                                            <div>
                                                <p className="FancyInput__text__title FancyInput__Text__title--dark">Double Sinks</p>
                                            </div>
                                            <div>
                                            <span className="custom-checkbox">
                                                <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </React.Fragment>
                )}

                {props.plumbingFixtureNew === "5piece" && (
                    <React.Fragment>
                        <div className="row">
                            <div className="col col-md">
                                <div className="FancyInputArea__section-heading">
                                    <p>Three Additional Plumbing Fixtures</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12">
                                <label className="FancyInput FancyInput--first">
                                    <input
                                        type="checkbox"
                                        className="FancyInput__radio-button"
                                        name="additionalPlumbingFixture5Piece"
                                        checked
                                    />

                                    <div className="FancyInput__Border">
                                        <div className="FancyInput__Text">
                                            <div>
                                                <p className="FancyInput__text__title FancyInput__Text__title--dark">Bathtub</p>
                                            </div>
                                            <div>
                                            <span className="custom-checkbox">
                                                <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className="col-md-12 col-12">
                                <label className="FancyInput">
                                    <input
                                        type="checkbox"
                                        className="FancyInput__radio-button"
                                        name="additionalPlumbingFixture5Piece"
                                        checked
                                    />
                                    <div className="FancyInput__Border">
                                        <div className="FancyInput__Text">
                                            <div>
                                                <p className="FancyInput__text__title FancyInput__Text__title--dark">Walk-in Shower</p>
                                            </div>
                                            <div>
                                            <span className="custom-checkbox">
                                                <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className="col-md-12 col-12">
                                <label className="FancyInput">
                                    <input
                                        type="checkbox"
                                        className="FancyInput__radio-button"
                                        name="additionalPlumbingFixture5Piece"
                                        checked
                                    />
                                    <div className="FancyInput__Border">
                                        <div className="FancyInput__Text">
                                            <div>
                                                <p className="FancyInput__text__title FancyInput__Text__title--dark">Double Sinks</p>
                                            </div>
                                            <div>
                                            <span className="custom-checkbox">
                                                <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }
}