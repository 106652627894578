import React from 'react';

export default function Step6(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title">Just a few more details…</h1>
                    <p className="main-subtitle">Your contact details will allow us to follow up with you.
                        But don’t worry, you will receive a competitive Instant Quote™ right after this. <br /> <br />
                        There is no obligation tied to this quote, no up-front costs, and it’s entirely free.
                    </p>
                </div>
            </div>

            <form className="instant-quote-details-form" autoComplete="off">
                <div className="row">
                    <div className="col-12 col-md">
                        <label htmlFor="name" className={`FancyInput FancyInput--first FancyInput--input-text`}>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Name"
                                defaultValue={props.name}
                                onChange={(e) => props.modifyData('name', e.currentTarget.value)}
                                required
                            />
                            <div className="FancyInput__Border">
                            </div>
                        </label>
                    </div>
                    <div className="col-12 col-md">
                        <label htmlFor="email" className={`FancyInput FancyInput--input-text`}>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                defaultValue={props.email}
                                onChange={(e) => props.modifyData('email', e.currentTarget.value)}
                                required
                            />
                            <div className="FancyInput__Border">
                            </div>
                        </label>
                    </div>
                    <div className="col-12 col-md">
                        <label htmlFor="phoneNumber" className={`FancyInput FancyInput--last FancyInput--input-text`}>
                            <input
                                type="tel"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="Phone number"
                                defaultValue={props.phoneNumber}
                                onChange={(e) => props.modifyData('phoneNumber', e.currentTarget.value)}
                                required
                            />
                            <div className="FancyInput__Border">
                            </div>
                        </label>
                    </div>
                </div>

                <div className="row">
                    <div className="col col-md">
                        <div className="FancyInputArea__section-heading">
                            <p>Optional</p>
                        </div>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12 col-md">
                    <label htmlFor="address" className={`FancyInput FancyInput--first FancyInput--input-text`}>
                      <textarea
                          id="address"
                          name="address"
                          rows="1"
                          placeholder="Address"
                          defaultValue={props.address}
                          onChange={(e) => props.modifyData('address', e.currentTarget.value)}
                          required
                      />
                        <div className="FancyInput__Border">
                        </div>
                    </label>
                </div>
            </div>

            <footer className="instant-quote__footer row">
                <div className="col-md col-12">
                    <p>Your contact information is protected by our Privacy Policy and is not shared with any third parties.</p>
                </div>
                <div className="col-md col-12">
                   <p>By pressing “See my Instant Quote™”, you consent to receive marketing emails, calls, and texts from Elfarro Design-Build,
                    including by automated means. You may opt-out at any time and consent is not a condition of sale.</p>
                </div>
            </footer>
        </div>
    )

}