import React from 'react';

export default function Step31(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title main-title--no-subtitle">What type of finishes will your basement have?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <label className="FancyInput FancyInput--first">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="materialFinishBasement"
                        />

                        <div className="FancyInput__Border" onClick={() => props.modifyData('materialFinishBasement', 'Standard Finishes')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Standard Finishes</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Carpet with standard trim and fixtures</p>
                                </div>
                                <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
                <div className="col-12 col-md-6">
                    <label className="FancyInput">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="materialFinishBasement"
                        />

                        <div className="FancyInput__Border" onClick={() => props.modifyData('materialFinishBasement', 'Elfarro Finishes')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark d-flex align-items-center">Elfarro Finishes</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Laminate flooring with upgraded trim and pot lights</p>
                                </div>
                                <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
                <div className="col-12 col-md-6">
                    <label className="FancyInput">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="materialFinishBasement"
                        />

                        <div className="FancyInput__Border" onClick={() => props.modifyData('materialFinishBasement', 'Upgraded Finishes')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Upgraded Finishes</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Engineered flooring with premium trim and high-quality fixtures</p>
                                </div>
                                <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
                <div className="col-12 col-md-6">
                    <label className="FancyInput FancyInput--last">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="materialFinishBasement"
                        />

                        <div className="FancyInput__Border" onClick={() => props.modifyData('materialFinishBasement', 'I\'m not sure')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">I'm not sure</p>
                                </div>
                                <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

        </div>
    )

}