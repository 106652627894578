import React from 'react';
import Step1 from './common/Step1.jsx';
import Step2 from './common/Step2.jsx';
import Step3 from './common/Step3.jsx';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

export default class GetEstimateSmall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      isEmailFilled: window.location.hash,
    };

    this.fieldValues = {
      name: null,
      email: null,
      phone: null,
      virtualMeet: 'no',
      renovationType: null,
      budget: null,
      timeline: null,
      address: null,
    };
  }

  _nextStep() {
    this.setState((previousState) => ({
      step: previousState.step + 1,
    }));
  }

  _previousStep() {
    this.setState((previousState) => ({
      step: previousState.step - 1,
    }));
  }

  renderStep() {
    switch (this.state.step) {
      case 1:
        return (
          <Step1
            key={1}
            fieldValues={this.fieldValues}
            step={this.state.step}
            previousStep={this._nextStep.bind(this)}
            nextStep={this._nextStep.bind(this)}
            saveValues={this._saveValues.bind(this)}
            setFirstStepAsCompleted={this.props._setFirstStepAsCompleted}
          />
        );

      case 2:
        return (
          <Step2
            key={2}
            fieldValues={this.fieldValues}
            step={this.state.step}
            previousStep={this._nextStep.bind(this)}
            nextStep={this._nextStep.bind(this)}
            saveValues={this._saveValues.bind(this)}
          />
        );

      case 3:
        return (
          <Step3
            key={3}
            fieldValues={this.fieldValues}
            step={this.state.step}
            previousStep={this._nextStep.bind(this)}
            nextStep={this._nextStep.bind(this)}
            saveValues={this._saveValues.bind(this)}
          />
        );
    }
  }

  _saveValues(fields) {
    // Remember, `fieldValues` is set at the top of this file, we are simply appending
    // to and overriding keys in `fieldValues` with the `fields` using Object.assign
    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign

    this.fieldValues = Object.assign({}, this.fieldValues, fields);

    this.props.saveValuesParent(this.fieldValues);
  }

  render() {
    return (
      <CSSTransitionGroup
        transitionName="example"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {this.renderStep()}
      </CSSTransitionGroup>
    );
  }
}
