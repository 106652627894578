import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step4(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title main-title--no-subtitle">Do you want to add a fireplace or an egress window?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12">
                    <label for="basementFireplaceOrEgress" className="FancyInput FancyInput--first">
                        <input
                            type="checkbox"
                            className="FancyInput__radio-button"
                            id="basementFireplaceOrEgress"
                            value="yes"
                            defaultChecked={props.basementFireplace}
                            checked={props.basementFireplace}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('basementFireplace', !props.basementFireplace)}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Gas Fireplace</p>
                                </div>

                                <div>
                                    <span className="custom-checkbox">
                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md-6 col-12">
                    <label htmlFor="basementFireplaceOrEgress" className="FancyInput FancyInput--last">
                        <input
                            type="checkbox"
                            className="FancyInput__radio-button"
                            id="basementFireplaceOrEgress"
                            value="Egress Window"
                            defaultChecked={props.basementEgress}
                            checked={props.basementEgress}
                        />
                        <div className="FancyInput__Border"
                             onClick={() => props.modifyData('basementEgress', !props.basementEgress)}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Egress Window</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Intended to provide an emergency means of exiting the basement</p>
                                </div>
                                <div>
                                    <span className="custom-checkbox">
                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    )

}