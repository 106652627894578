import React from 'react';
import $ from 'jquery';
import validate from 'jquery-validation';
import axios from 'axios';
import LaddaButton, { L, ZOOM_IN } from 'react-ladda';
import ReactGA from 'react-ga';
import autoBind from 'react-autobind';
ReactGA.initialize('UA-100111269-1');

$.validator.addMethod(
  'usPhoneFormat',
  function (value, element) {
    return this.optional(element) || /^\(\d{3}\) \d{3}\-\d{4}/.test(value);
  },
  'We need your phone number'
);

$.validator.addMethod(
  'emailNew',
  function (value, element) {
    return (
      this.optional(element) ||
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
    );
  },
  'Enter a valid email'
);

export default class Step1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFormValid: false,
      loading: false,
      emailFromUrl: window.location.hash,
    };

    autoBind(this);
  }

  componentDidMount() {
    $('.input-item input').on('focus blur', function () {
      $(this).parent().parent().toggleClass('input-item--active');
    });

    $('.step-1.get-estimate-form').validate({
      errorPlacement: (error, element) => {
        error.insertAfter($(element).parent().parent());
      },

      rules: {
        name: {
          required: true,
          maxlength: 20,
        },

        email: {
          required: true,
          email: true,
        },

        phone: {
          required: true,
          number: true,
          maxlength: 10,
          minlength: 10,
        },
      },

      messages: {
        name: {
          required: 'We need your name',
        },

        email: {
          required: 'We need your email',
          emailNew: 'Enter a valid email',
        },

        phone: {
          required: 'We need your phone number',
          minlength: 'Enter a 10-digit number',
          maxlength: 'Enter a 10-digit number',
        },
      },
    });
  }

  _trim(e) {
    e.currentTarget.value = e.currentTarget.value.trim();
  }

  toggle() {
    this.setState({
      loading: !this.state.loading,
    });
  }

  saveAndContinue(e) {
    e.preventDefault();

    if ($('.step-1.get-estimate-form').valid()) {
      this.toggle();

      //saveData in the database
      // update the field values as well in the parent component

      var dataToSendStep1 = {
        name: this.refs.name.value,
        email: this.refs.email.value,
        phone: this.refs.phone.value,
      };

      if (typeof sessionStorage.getItem('conversionElement') === 'string') {
        dataToSendStep1.conversionInfoPage =
          document.body.getAttribute('data-page') || 'none';
        dataToSendStep1.conversionInfoElement =
          sessionStorage.getItem('conversionElement');
      }

      console.log(dataToSendStep1);

      axios
        .post('/signup', dataToSendStep1)
        .then((response) => {
          console.log(response);
          console.log(response.data._id);

          this.props.saveValues({
            userId: response.data._id,
            name: this.refs.name.value,
            email: this.refs.email.value,
            phone: this.refs.phone.value,
            step1Done: true,
          });

          goog_report_conversion();

          ReactGA.event({
            category: 'User',
            action: 'Form Submitted - Step 1',
          });

          this.props.nextStep();

          $('html, body').animate(
            {
              scrollTop: 0,
            },
            50
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  render() {
    return (
      <div>
        <section className="form">
          <form className="step-1 get-estimate-form" autoComplete="off">
            <div className="container-almost-full">
              <div className="flex-row d-flex align-items-center justify-content-around">
                <div className="col-md-10 col-lg-10 text-center">
                  <h2 className="sub-title">
                    Book an in-home consultation and get your free, fixed
                    written proposal for your renovation project.
                  </h2>
                </div>
              </div>

              <div className="row row-flex align-items-center justify-content-around">
                <div className="col-md-8">
                  <div className="input-item">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        name="name"
                        ref="name"
                        placeholder="First Name"
                        onBlur={this._trim}
                      />
                      <span className="success-check"></span>
                    </div>
                  </div>

                  <div className="input-item">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        name="email"
                        id="step1-email"
                        ref="email"
                        placeholder="Email"
                        onBlur={this._trim}
                      />
                      <span className="success-check"></span>
                    </div>
                  </div>

                  <div className="input-item">
                    <div className="input-wrapper">
                      <input
                        type="text"
                        name="phone"
                        ref="phone"
                        id="phone"
                        placeholder="Phone Number"
                      />
                      <span className="success-check"></span>
                    </div>
                  </div>

                  <div className="input-item">
                    <LaddaButton
                      className="btn"
                      loading={this.state.loading}
                      onClick={this.saveAndContinue}
                      data-color="#32dd9d"
                      data-size={L}
                      data-style={ZOOM_IN}
                      data-spinner-size={30}
                      data-spinner-color="#ddd"
                      data-spinner-lines={12}
                    >
                      continue <span className="link-arrow">→</span>
                    </LaddaButton>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    );
  }
}
