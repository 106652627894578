import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step41(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title main-title--no-subtitle">Do you want to remove any existing plumbing fixtures?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6 col-12">
                    <label className="FancyInput">
                        <input type="radio" className="FancyInput__radio-button" name="removeExistingPlumbingFixture" value="yes" checked={props.removeExistingPlumbingFixture === 'Yes'} />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('removeExistingPlumbingFixture', 'Yes')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Yes</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md-6 col-12">
                    <label className="FancyInput FancyInput--last">
                        <input type="radio" className="FancyInput__radio-button" name="removeExistingPlumbingFixture" value="no" checked={props.removeExistingPlumbingFixture === 'No'} />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('removeExistingPlumbingFixture', 'No')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">No</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <PoseGroup>
                {props.removeExistingPlumbingFixture === "Yes"  && (
                    <AnimatedBox key='removeExistingPlumbingFixtures'>
                        <div className="row">
                            <div className="col-12 col-md-12">
                                <div className="FancyInputArea__section-heading">
                                    <p>Remove Existing Plumbing Fixture</p>
                                </div>
                            </div>
                        </div>

                        {props.plumbingFixtureExisting === '3piece' && (
                            <div className="row">
                                <div className="col-md-12 col-12">
                                    <label className="FancyInput FancyInput--first">
                                        <input
                                            type="checkbox"
                                            className="FancyInput__radio-button"
                                            name="removeExistingPlumbingFixturesSelected"
                                            defaultChecked={props.removeExistingPlumbingFixture3piece === props.additionalPlumbingFixtureExisting}
                                        />

                                        <div className="FancyInput__Border" onClick={() => props.modifyData('removeExistingPlumbingFixture3piece', props.additionalPlumbingFixtureExisting)}>
                                            <div className="FancyInput__Text">
                                                <div>
                                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">{props.additionalPlumbingFixtureExisting}</p>
                                                </div>
                                                <div>
                                                    <span className="custom-checkbox">
                                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        )}


                        {props.plumbingFixtureExisting === '4piece' && (
                            <div className="row">
                                {props.additionalPlumbingFixture4PieceExisting.map((fixture, index) => (
                                    <div className="col-md-6 col-12">
                                        <label className={`FancyInput ${index === 0 ? 'FancyInput--first' : ''}`}>
                                            <input
                                                type="checkbox"
                                                className="FancyInput__radio-button"
                                                name="removeExistingPlumbingFixturesSelected"
                                                defaultChecked={props.removeExistingPlumbingFixturesSelected.indexOf(fixture) !== -1}
                                            />

                                            <div
                                                className="FancyInput__Border"
                                                 onClick={() => props.modifyData('removeExistingPlumbingFixturesSelected', fixture, true)}
                                            >
                                                <div className="FancyInput__Text">
                                                    <div>
                                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">{fixture}</p>
                                                    </div>
                                                    <div>
                                                    <span className="custom-checkbox">
                                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        )}

                        {props.plumbingFixtureExisting === '5piece' && (
                           <div className="row">
                               <div className="col-md-6 col-12">
                                   <label className="FancyInput FancyInput--first">
                                       <input
                                           type="checkbox"
                                           className="FancyInput__radio-button"
                                           name="removeExistingPlumbingFixturesSelected"
                                           defaultChecked={props.removeExistingPlumbingFixturesSelected.indexOf('Bathtub') !== -1}
                                       />

                                       <div className="FancyInput__Border" onClick={() => props.modifyData('removeExistingPlumbingFixturesSelected', 'Bathtub', true)}>
                                           <div className="FancyInput__Text">
                                               <div>
                                                   <p className="FancyInput__text__title FancyInput__Text__title--dark">Bathtub</p>
                                               </div>
                                               <div>
                                                    <span className="custom-checkbox">
                                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                    </span>
                                               </div>
                                           </div>
                                       </div>
                                   </label>
                               </div>
                               <div className="col-md-6 col-12">
                                   <label className="FancyInput">
                                       <input
                                           type="checkbox"
                                           className="FancyInput__radio-button"
                                           name="removeExistingPlumbingFixturesSelected"
                                           defaultChecked={props.removeExistingPlumbingFixturesSelected.indexOf('Walk-in Shower') !== -1}
                                       />

                                       <div className="FancyInput__Border" onClick={() => props.modifyData('removeExistingPlumbingFixturesSelected', 'Walk-in Shower', true)}>
                                           <div className="FancyInput__Text">
                                               <div>
                                                   <p className="FancyInput__text__title FancyInput__Text__title--dark">Walk-in Shower</p>
                                               </div>
                                               <div>
                                                    <span className="custom-checkbox">
                                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                    </span>
                                               </div>
                                           </div>
                                       </div>
                                   </label>
                               </div>
                               <div className="col-md-6 col-12">
                                   <label className="FancyInput">
                                       <input
                                           type="checkbox"
                                           className="FancyInput__radio-button"
                                           name="removeExistingPlumbingFixturesSelected"
                                           defaultChecked={props.removeExistingPlumbingFixturesSelected.indexOf('Double Sinks') !== -1}
                                       />

                                       <div className="FancyInput__Border" onClick={() => props.modifyData('removeExistingPlumbingFixturesSelected', 'Double Sinks', true)}>
                                           <div className="FancyInput__Text">
                                               <div>
                                                   <p className="FancyInput__text__title FancyInput__Text__title--dark">Double Sinks</p>
                                               </div>
                                               <div>
                                                    <span className="custom-checkbox">
                                                        <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                                    </span>
                                               </div>
                                           </div>
                                       </div>
                                   </label>
                               </div>
                           </div>
                        )}

                    </AnimatedBox>
                )}
            </PoseGroup>
        </div>
    )

}