import React from 'react';

export default function Step31(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title">What type of finishes will your {props.existingBathroom === "Yes" ? 'renovated' : 'new'} bathroom have?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <label className="FancyInput FancyInput--first">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="materialFinish"
                        />

                        <div className="FancyInput__Border" onClick={() => props.modifyData('materialFinish', 'Standard Finishes')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Standard Finishes</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Laminate countertops, standard flooring, and standard fixtures</p>
                                </div>
                                <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
                <div className="col-12 col-md-6">
                    <label className="FancyInput">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="materialFinish"
                        />

                        <div className="FancyInput__Border" onClick={() => props.modifyData('materialFinish', 'Elfarro Finishes')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark d-flex align-items-center">Elfarro Finishes</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Stone countertop and tiled flooring with high-quality fixtures</p>
                                </div>
                                <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
                <div className="col-12 col-md-6">
                    <label className="FancyInput">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="materialFinish"
                        />

                        <div className="FancyInput__Border" onClick={() => props.modifyData('materialFinish', 'Upgraded Finishes')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">Upgraded Finishes</p>
                                    <p className="FancyInput__text__title FancyInput__Text__title--light">Upgraded stone countertops, upgraded fixtures, and upgraded tile</p>
                                </div>
                                <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
                <div className="col-12 col-md-6">
                    <label className="FancyInput FancyInput--last">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="materialFinish"
                        />

                        <div className="FancyInput__Border" onClick={() => props.modifyData('materialFinish', 'I\'m not sure')}>
                            <div className="FancyInput__Text">
                                <div>
                                    <p className="FancyInput__text__title FancyInput__Text__title--dark">I'm not sure</p>
                                </div>
                                <div>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

        </div>
    )

}