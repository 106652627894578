import React from 'react';
import { PoseGroup }from "react-pose";
import AnimatedBox from '../AnimatedBoxExitEnter';

export default function Step2(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md col">
                    <h1 className="main-title main-title--no-subtitle">Are you renovating an existing bathroom?</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-md col-12">
                    <label className="FancyInput">
                        <input type="radio"
                               className="FancyInput__radio-button"
                               name="existingBath"
                               value="yes"
                               defaultChecked={props.existingBathroom === 'Yes'}
                               checked={props.existingBathroom === 'Yes'}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('existingBathroom', 'Yes')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">Yes</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>

                <div className="col-md col-12">
                    <label className="FancyInput FancyInput--last">
                        <input
                            type="radio"
                            className="FancyInput__radio-button"
                            name="existingBath"
                            value="no"
                            defaultChecked={props.existingBathroom === 'No'}
                            checked={props.existingBathroom === 'No'}
                        />
                        <div className="FancyInput__Border" onClick={() => props.modifyData('existingBathroom', 'No')}>
                            <div className="FancyInput__Text">
                                <p className="FancyInput__text__title">No</p>
                                <span className="custom-radio">
                                    <span className="custom-radio__inner"></span>
                                </span>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <PoseGroup>
                {props.existingBathroom === "No"  && (
                    <AnimatedBox key="noExistingBathroom">
                        <div className="row">
                            <div className="col col-md">
                                <div className="FancyInputArea__section-heading">
                                    <p>Floor Plan Layout</p>
                                </div>
                            </div>
                        </div>

                        <label className="FancyInput FancyInput--first">
                            <input
                               type="checkbox"
                               className="FancyInput__radio-button FancyInput__radio-button--checked-disabled"
                               name="unfinishedBasement"
                               checked
                            />

                            <div className="FancyInput__Border">
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">Unfinished Basement</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Water supply and drain pipes have been roughed in</p>
                                    </div>
                                    <div>
                                        <span className="custom-checkbox">
                                            <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </AnimatedBox>
                )}
            </PoseGroup>

            <PoseGroup>
                {props.existingBathroom === "Yes"  && (
                    <AnimatedBox key="existingBathroom">
                        <div className="row">
                            <div className="col col-md">
                                <div className="FancyInputArea__section-heading">
                                    <p>Floor Plan Layout</p>
                                </div>
                            </div>
                        </div>

                        <label className="FancyInput FancyInput--first">
                            <input
                                type="checkbox"
                                className="FancyInput__radio-button"
                                name="ensuiteBathroom"
                                defaultChecked={props.ensuiteBathroom}
                                checked={props.ensuiteBathroom}
                            />

                            <div className="FancyInput__Border" onClick={() => props.modifyData('ensuiteBathroom', !props.ensuiteBathroom)}>
                                <div className="FancyInput__Text">
                                    <div>
                                        <p className="FancyInput__text__title FancyInput__Text__title--dark">Ensuite Bathroom</p>
                                        <p className="FancyInput__text__title FancyInput__Text__title--light">Bathroom is connected to a bedroom</p>
                                    </div>
                                    <div>
                                        <span className="custom-checkbox">
                                            <img src="/resources/front/img/Elfarro-Checkmark.svg" alt=""/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </AnimatedBox>
                )}
            </PoseGroup>

        </div>
    )

}